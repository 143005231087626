<template>
  <div v-if="loaded">
    
   <h1>商品オプション2選択ページサンプル</h1>
   <p>このページで追加のオプション2を選択させる。</p>
    

<p>オプション2</p>


<div>
  
	<plugin_itemoptions :data="value" :options_filtered="options_filtered" :cartIndex="0" :posterrors="errors_global" />
</div>
<router-link @click.native="scrollToTop" :to="'/item/'+label+'/option1'">前へもどる</router-link> | 

<router-link @click.native="scrollToTop" :to="'/item/'+label+'/sender'">申し込みを続ける</router-link>


  </div>
</template>

<script>
//import { mapState, mapMutations, mapActions } from "vuex";
import plugin_itemoptions from "@/components/itemoption";
export default  {
  components:{plugin_itemoptions},
  data:function(){
    return {
      typetag:"option3",
      value:[],
    }
  },
  created:function(){
      this.loaded_countmax=1;
      //まず商品定義を読み込む
      //alert(this.$route.params.label)
      this.get_itemoption(this.$route.params.label).then(()=>{
        this.options_filtered=this.options_filter("option3");
        console.log("options_filtered",this.options_filtered);
        //this.option_available=true;
        this.loaded_countup();
      });
  },
  computed:{
    //...mapState(["options"]),
    label:function(){
      return this.$route.params.label;
    },
    ID:function(){
      return this.$route.params.ID;
    }
  },
  methods:{
    //...mapMutations([]),
    //...mapActions([]),
  }
  
}
</script>

<style scoped>
</style>