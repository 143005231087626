import { render, staticRenderFns } from "./option2.vue?vue&type=template&id=97cd82fa&scoped=true&"
import script from "./option2.vue?vue&type=script&lang=js&"
export * from "./option2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97cd82fa",
  null
  
)

export default component.exports